.Options {
    padding: 80px 20px;
    //border: 2px solid var(--bg-olive);
    overflow: hidden !important;
    //display: flex;
    //gap: 20px;
    // XS - Phone
    @media (max-width: 480px) {
    }

    // S - Tablet
    @media (max-width: 768px) {
        padding: 20px 20px;
    }

    // M - Tablet/Laptop
    @media (max-width: 1024px) {
    }

    // L - High Res
    @media (max-width: 1200px) {
    }

    // XL - Ultra High Res
    @media (min-width: 1201px) {
    }
}
