@use 'sass:map';
@use '../functions/functions';
@use '../tokens/config';

@each $variant in map.get(config.$headings, 'variant') {
    @each $t, $s in $variant {
        #{$t} {
            font-family: 'heading';
            font-size: functions.pxToRem($s);
        }
    }
}
