.OptionItem {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 20px;
    cursor: pointer;

    &:hover img {
        box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.45);
    }

    a {
        color: var(--fg-olive) !important;
    }

    // XS - Phone
    @media (max-width: 480px) {
    }

    // S - Tablet
    @media (max-width: 768px) {
        margin-top: 20px;
        h4 {
            font-size: 16px;
        }
    }

    // M - Tablet/Laptop
    @media (max-width: 1024px) {
    }

    // L - High Res
    @media (max-width: 1200px) {
    }

    // XL - Ultra High Res
    @media (min-width: 1201px) {
    }
}
