.Button {
    background-color: var(--bg-olive);
    display: inline-block;
    padding: 8px 16px;
    text-transform: lowercase;
    color: var(--fg-white);
    font-weight: 400;
    font-size: 16px;
    cursor: pointer;
    transition: var(--transition);

    max-width: 240px;
    text-align: center;
    min-width: 160px;

    &:hover {
        background-color: var(--bg-olive-dark-10);
        box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.45);
    }

    &[data-active='false'] {
        background-color: var(--bg-white);
        color: var(--fg-black);
        &:hover {
            background-color: var(--bg-olive-dark-10);
            box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.45);
            color: var(--fg-white);
        }
    }

    &[data-object-type='pill'] {
        border-radius: 50px;
        min-width: 60px;
        text-wrap: nowrap;
    }

    &[data-object-type='inverse'] {
        min-width: 120px;
        background-color: transparent;
        border: 2px solid var(--bg-white);
        color: var(--fg-white);
        &:hover {
            background-color: var(--bg-white);
            box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.45);
            color: var(--fg-olive);
        }
    }
    // XS - Phone
    @media (max-width: 480px) {
        padding: 8px 12px;
        font-size: 10px;
    }

    // S - Tablet
    @media (max-width: 768px) {
        padding: 8px 12px;
        font-size: 10px;
    }

    // M - Tablet/Laptop
    @media (max-width: 1024px) {
    }

    // L - High Res
    @media (max-width: 1200px) {
    }

    // XL - Ultra High Res
    @media (min-width: 1201px) {
    }

    // XS - Phone
    @media (max-width: 480px) {
    }

    // S - Tablet
    @media (max-width: 768px) {
    }

    // M - Tablet/Laptop
    @media (max-width: 1024px) {
    }

    // L - High Res
    @media (max-width: 1200px) {
    }

    // XL - Ultra High Res
    @media (min-width: 1201px) {
    }
}
