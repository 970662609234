.MobileBar {
    display: none;
    // XS - Phone
    @media (max-width: 480px) {
    }

    // S - Tablet
    @media (max-width: 768px) {
        display: flex;
        background-color: var(--bg-light);

        .mcol-1 {
            background-color: var(--bg-darkest);
            a {
                color: #fff !important;
            }
        }
        .mcol-2 {
            background-color: var(--bg-olive);
            a {
                color: #fff !important;
            }
        }

        .MobileBar__col {
            padding: 8px;
            flex-direction: column;
            font-size: 12px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                width: 80px;
            }
        }
    }

    // M - Tablet/Laptop
    @media (max-width: 1024px) {
    }

    // L - High Res
    @media (max-width: 1200px) {
    }

    // XL - Ultra High Res
    @media (min-width: 1201px) {
    }
}
