.AboutUs {
    background-color: var(--bg-olive);
    padding: 4% 0;
    overflow-x: hidden !important;

    .button-bar {
        display: flex;
        justify-content: center;
        margin-top: 40px;
    }

    img {
        box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.45);
    }

    h2 {
        margin: 0 0 40px 0;
    }

    a {
        color: var(--fg-white);
        text-decoration: none;
    }

    // XS - Phone
    @media (max-width: 480px) {
    }

    // S - Tablet
    @media (max-width: 768px) {
        padding: 5%;
        h2 {
            margin-top: 20px;
        }
    }

    // M - Tablet/Laptop
    @media (max-width: 1024px) {
    }

    // L - High Res
    @media (max-width: 1200px) {
    }

    // XL - Ultra High Res
    @media (min-width: 1201px) {
    }
}

.options {
    margin-top: -60px;
}
