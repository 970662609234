.PriceTag {
    font-weight: normal !important;
    font-family: 'main-text-regular', sans-serif;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;

    padding: 10px !important;
    //padding: $size / 6;
    //border-radius: 50%;
    box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.2);

    justify-content: center;
    align-items: center;
    //transform: rotate(15deg);
    background-color: var(--bg-yellow);

    &:hover {
        background-color: var(--bg-yellow-dark-20);
        transform: rotate(0deg);
    }

    .text-big {
        font-size: 1rem !important;
        font-weight: 700;
        color: var(--fg-black);
        text-align: center;
        width: 100%;
    }

    .text-medium {
        color: var(--fg-white);
        font-size: 1rem !important;
        //color: var(--fg-black);
        text-align: center;
        width: 100%;
        padding: 5px;
    }

    .text-small {
        font-size: 1rem;
    }

    // XS - Phone
    @media (max-width: 480px) {
    }

    // S - Tablet
    @media (max-width: 768px) {
        .text-big {
            .text-medium {
                font-size: 0.8rem !important;
            }
        }
    }

    // M - Tablet/Laptop
    @media (max-width: 1024px) {
    }

    // L - High Res
    @media (max-width: 1200px) {
    }

    // XL - Ultra High Res
    @media (min-width: 1201px) {
    }
}
