.ProductPage {
    background-color: var(--bg-light);

    .ProductPage__filters {
        margin-left: 5%;
        padding: 40px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;
    }

    // XS - Phone
    @media (max-width: 480px) {
    }

    // S - Tablet
    @media (max-width: 768px) {
    }

    // M - Tablet/Laptop
    @media (max-width: 1024px) {
        .ProductPage__filters {
            margin-left: 1%;
            padding: 20px 10px;
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            align-items: center;
            gap: 10px;
        }
    }

    // L - High Res
    @media (max-width: 1200px) {
    }

    // XL - Ultra High Res
    @media (min-width: 1201px) {
    }
}
