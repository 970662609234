@use 'sass:map';
@use 'sass:string';
@use '../functions/functions';
@use '../tokens/config';

/** CUSTOM PROPERTIES **/
:root {
    /** COLOR **/
    @each $ct, $cd in config.$color-types {
        /*! #{$cd} */
        @each $style in config.$colors {
            $type: map.get($style, 'type');
            /*! #{$type} */
            $shades: functions.rootColor($type, $ct, $style);
            @each $shade, $value in $shades {
                #{$shade}: #{$value};
            }
        }
    }

    /** FONT **/
    @each $entry in config.$fonts {
        $type: map.get($entry, 'type');
        $q: string.quote($type);
        //--#{$type}: '#{$type}';
        --#{$type}: '#{$q}';
    }

    --transition: all 0.4s ease;
}
