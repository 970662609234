@use 'sass:map';
@use 'sass:string';
@use '../functions/functions';
@use '../tokens/config';

/** UTILITY: Colors **/
@each $ct, $cd, $cp in config.$color-types {
    /*! #{$cd} */
    @each $style in config.$colors {
        $type: map.get($style, 'type');

        /*! #{$type} */
        $shades: functions.utilityColor($type, $ct, $style);
        @each $shade, $value in $shades {
            @if $ct == 'bg' {
                #{$shade} {
                    background-color: string.unquote($value);
                }
            } @else {
                #{$shade} {
                    color: string.unquote($value);
                }
            }
        }
    }
}
