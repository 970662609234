.MakeAppointment {
    position: fixed;
    bottom: 60px;
    right: 20px;
    background-color: var(--bg-yellow);
    color: #fff;
    font-size: 16px;
    width: 180px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.45);
    cursor: pointer;

    &:hover {
        background-color: var(--bg-yellow-dark-20);
    }

    // XS - Phone
    @media (max-width: 480px) {
        bottom: 80px;
    }

    // S - Tablet
    @media (max-width: 768px) {
    }

    // M - Tablet/Laptop
    @media (max-width: 1024px) {
    }

    // L - High Res
    @media (max-width: 1200px) {
    }

    // XL - Ultra High Res
    @media (min-width: 1201px) {
    }
}
