.InfoBlock {
    //$h: 50vh;
    $minH: 400px;
    $h: 500px;
    $mh: 800px;
    $skip: -320px;
    width: 100%;
    //overflow-y: hidden !important;
    overflow-x: hidden !important;
    min-height: $minH;

    h2 {
        margin-bottom: 20px;
    }

    .description {
        width: 40vw;
    }

    .shadowed {
        box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.45);
    }

    .InfoBlock__left {
        .left-row {
            background-color: var(--bg-white);
            min-height: $minH;
            max-height: $mh;
        }

        .left-image {
            background-color: var(--bg-darkest);
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 30px;
            img {
                margin-left: $skip;
                transform: scaleX(-1);
            }
        }

        .left-content {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10%;
            max-height: $mh;
        }
    }

    .InfoBlock__right {
        .right-row {
            background-color: var(--bg-light);
            min-height: $minH;
            max-height: $mh;
        }

        .right-image {
            background-color: var(--bg-olive);
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 30px;

            img {
                margin-right: $skip;

                .shadowed {
                    box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.45);
                }
            }
        }

        .right-content {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10%;
            max-height: $h;
        }
    }

    // XS - Phone
    @media (max-width: 480px) {
    }

    // S - Tablet
    @media (max-width: 1024px) {
        .description {
            width: 100%;
        }

        .InfoBlock__right {
            .right-row {
                background-color: var(--bg-light);
                min-height: 0;
                max-height: none;
            }

            .right-image {
                background-color: var(--bg-olive);
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 30px;

                img {
                    margin-right: 0;
                    width: 80%;

                    .shadowed {
                        box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.45);
                    }
                }
            }

            .right-content {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10%;
                max-height: none;
            }
        }

        .InfoBlock__left {
            .left-row {
                background-color: var(--bg-white);
                min-height: 0;
                max-height: none;
            }

            .left-image {
                background-color: var(--bg-darkest);
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 30px;
                img {
                    width: 80%;
                    margin-left: 0;
                    transform: scaleX(-1);
                }
            }

            .left-content {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10%;
                max-height: none;
            }
        }
    }

    // M - Tablet/Laptop
    @media (max-width: 1024px) {
    }

    // L - High Res
    @media (max-width: 1200px) {
    }

    // XL - Ultra High Res
    @media (min-width: 1201px) {
    }
}
