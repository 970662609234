.Menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: space-between;
    padding: 2% 9%;
    transition: var(--transition);
    z-index: 1000;

    .menu-desktop {
        display: block;
    }

    .menu-mobile,
    .overlay-menu {
        display: none;
    }

    .menu-logo {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        img {
            display: none;
            height: 60px;
        }
    }

    &[data-theme='normal'] {
        background-color: transparent;
        box-shadow: none;

        .menu-mobile,
        .overlay-menu {
            color: white;
            ul li {
                color: var(--fg-olive) !important;
            }
            a {
                color: var(--fg-olive) !important;
            }
        }

        ul {
            display: flex;
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                margin: 0 20px;
                color: white;
                font-family: 'main-text-bold', serif;
                cursor: pointer;

                a {
                    color: white;
                    text-decoration: none;
                }

                &[data-active='true'] {
                    border-bottom: 4px solid var(--fg-white);
                }

                &[data-object-type='product-link'] {
                    a {
                        opacity: 0.5;
                    }
                }
            }
        }
    }

    &[data-theme='scroll'] {
        background-color: var(--bg-white);
        box-shadow: 0 0 22px 8px rgba(0, 0, 0, 0.25);
        .menu-logo {
            img {
                display: block;
            }
        }

        ul {
            display: flex;
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                margin: 0 10px;
                font-family: 'main-text-bold';
                cursor: pointer;
                color: var(--fg-dark);

                a {
                    color: var(--fg-dark);
                    text-decoration: none;
                }

                &[data-active='true'] {
                    border-bottom: 4px solid var(--fg-olive);
                }

                &[data-object-type='product-link'] {
                    a {
                        color: var(--fg-light-dark-20);
                        opacity: 1;
                    }
                }
            }
        }
    }

    // XS - Phone
    @media (max-width: 480px) {
    }

    // S - Tablet
    @media (max-width: 768px) {
    }

    // M - Tablet/Laptop
    @media (max-width: 1200px) {
        .menu-desktop {
            display: none;
        }

        .menu-mobile {
            display: block;
            position: fixed;
            right: 20px;
            top: 20px;
            color: var(--fg-olive);
            z-index: 10000;
        }

        .overlay-menu {
            display: block;
            position: fixed;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background-color: var(--bg-white);
            z-index: 9999;
            padding: 30px;
            .mobile-logo {
                img {
                    height: 40px;
                }
            }
            .menu-container {
                padding: 40px;
                display: flex;
                align-items: center;
                justify-content: center;

                ul {
                    flex-direction: column;
                    gap: 20px;
                    li {
                        width: 200px;
                    }
                }
            }
        }
    }

    // L - High Res
    @media (max-width: 1200px) {
    }

    // XL - Ultra High Res
    @media (min-width: 1201px) {
    }
}
