.InputField {
    width: 100%;
    padding: 10px 20px;
    margin-top: 10px;
    margin-bottom: 10px;

    border: 1px solid transparent;
    border-bottom: 1px solid var(--bg-olive);

    &:focus {
        outline: none;
        background-color: var(--bg-olive-light-20);
        color: var(--fg-white);
    }

    // XS - Phone
    @media (max-width: 480px) {
    }

    // S - Tablet
    @media (max-width: 768px) {
    }

    // M - Tablet/Laptop
    @media (max-width: 1024px) {
    }

    // L - High Res
    @media (max-width: 1200px) {
    }

    // XL - Ultra High Res
    @media (min-width: 1201px) {
    }
}
