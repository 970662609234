.Footer {
    //width: 100%;
    background-color: var(--bg-darkest);
    color: var(--fg-white);
    overflow-x: hidden !important;
    padding: 4% 0 100px;

    .text {
        color: var(--fg-white-muted);
    }

    a {
        color: var(--fg-white) !important;
    }

    .data-col {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;
        padding: 20px 0;
    }

    .data-col-text {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 20px;
        padding: 20px 0;
    }

    .social {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        padding: 20px 0;
        a {
            img {
                width: 30px;
            }
        }
    }

    .logo {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: 2%;
        height: 80px;
        img {
            //opacity: 0.2;
            width: 100px;
        }
    }

    .full-width-center {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 40px 0;
    }

    .links {
        ul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            justify-content: center;
            gap: 40px;

            li {
                height: 30px;
            }
        }

        ul li a {
            opacity: 0.6;
            margin-bottom: 20px;
            font-weight: normal;
            font-family: 'main-text-regular', sans-serif !important;

            &:hover {
                opacity: 1;
            }
        }
    }

    // XS - Phone
    @media (max-width: 480px) {
    }

    // S - Tablet
    @media (max-width: 768px) {
        .data-col,
        .data-col-text {
            margin-top: 20px;
            flex-direction: column;
            align-items: center;
            ul li {
                text-align: center;
            }
        }
    }

    // M - Tablet/Laptop
    @media (max-width: 1024px) {
    }

    // L - High Res
    @media (max-width: 1200px) {
    }

    // XL - Ultra High Res
    @media (min-width: 1201px) {
    }
}
