/* Box sizing rules */
*,
*::before,
*::after {
    box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
figure,
blockquote,
dl,
dd {
    margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
    list-style: none;
}

/* Set core root defaults */
html:focus-within {
    scroll-behavior: smooth;
}

/* Set core body defaults */
body {
    min-height: 100vh;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
    text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
    max-width: 100%;
    display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
    font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
    html:focus-within {
        scroll-behavior: auto;
    }
    *,
    *::before,
    *::after {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}
a {
    text-decoration: none;
}

/** FONTS **/
/*! main-text-regular */
@font-face {
    font-family: 'main-text-regular';
    src:
        url('../fonts/phenomena-regular-webfont.woff') format('woff'),
        url('../fonts/phenomena-regular-webfont.woff2') format('woff2');
}
/*! main-text-bold */
@font-face {
    font-family: 'main-text-bold';
    src:
        url('../fonts/phenomena-bold-webfont.woff') format('woff'),
        url('../fonts/phenomena-bold-webfont.woff2') format('woff2');
}
/*! main-text-link */
@font-face {
    font-family: 'main-text-link';
    src:
        url('../fonts/phenomena-regular-webfont.woff') format('woff'),
        url('../fonts/phenomena-regular-webfont.woff2') format('woff2');
}
/*! label-text */
@font-face {
    font-family: 'label-text';
    src:
        url('../fonts/phenomena-regular-webfont.woff') format('woff'),
        url('../fonts/phenomena-regular-webfont.woff2') format('woff2');
}
/*! heading */
@font-face {
    font-family: 'heading';
    src:
        url('../fonts/phenomena-bold-webfont.woff') format('woff'),
        url('../fonts/phenomena-bold-webfont.woff2') format('woff2');
}
/** CUSTOM PROPERTIES **/
:root {
    /** COLOR **/
    /*! Background Color */
    /*! black */
    --bg-black: #000;
    /*! black-muted */
    --bg-black-muted: rgba(0, 0, 0, 0.6);
    /*! white */
    --bg-white: #fff;
    /*! white-muted */
    --bg-white-muted: rgba(255, 255, 255, 0.6);
    /*! darkest */
    --bg-darkest: #343a40;
    --bg-darkest-light-10: rgb(74.8620689655, 83.5, 92.1379310345);
    --bg-darkest-light-20: rgb(97.724137931, 109, 120.275862069);
    --bg-darkest-light-30: rgb(122.0344827586, 134.5, 146.9655172414);
    --bg-darkest-dark-10: rgb(29.1379310345, 32.5, 35.8620689655);
    --bg-darkest-dark-20: rgb(6.275862069, 7, 7.724137931);
    --bg-darkest-dark-30: hsl(210, 10.3448275862%, -7.2549019608%);
    /*! dark */
    --bg-dark: #707070;
    --bg-dark-light-10: rgb(137.5, 137.5, 137.5);
    --bg-dark-light-20: #a3a3a3;
    --bg-dark-light-30: rgb(188.5, 188.5, 188.5);
    --bg-dark-dark-10: rgb(86.5, 86.5, 86.5);
    --bg-dark-dark-20: #3d3d3d;
    --bg-dark-dark-30: rgb(35.5, 35.5, 35.5);
    /*! olive */
    --bg-olive: #827a65;
    --bg-olive-light-10: rgb(155.3116883117, 147.4155844156, 126.6883116883);
    --bg-olive-light-20: rgb(177.6103896104, 171.4805194805, 155.3896103896);
    --bg-olive-light-30: rgb(199.9090909091, 195.5454545455, 184.0909090909);
    --bg-olive-dark-10: rgb(101.2987012987, 95.0649350649, 78.7012987013);
    --bg-olive-dark-20: rgb(72.5974025974, 68.1298701299, 56.4025974026);
    --bg-olive-dark-30: rgb(43.8961038961, 41.1948051948, 34.1038961039);
    /*! light */
    --bg-light: #f2f2f2;
    --bg-light-light-10: hsl(0, 0%, 104.9019607843%);
    --bg-light-light-20: hsl(0, 0%, 114.9019607843%);
    --bg-light-light-30: hsl(0, 0%, 124.9019607843%);
    --bg-light-dark-10: rgb(216.5, 216.5, 216.5);
    --bg-light-dark-20: #bfbfbf;
    --bg-light-dark-30: rgb(165.5, 165.5, 165.5);
    /*! yellow */
    --bg-yellow: #abc35b;
    --bg-yellow-light-10: rgb(190.125, 208.6607142857, 128.3392857143);
    --bg-yellow-light-20: rgb(209.25, 222.3214285714, 165.6785714286);
    --bg-yellow-light-30: rgb(228.375, 235.9821428571, 203.0178571429);
    --bg-yellow-dark-10: rgb(146.875, 172.0535714286, 62.9464285714);
    --bg-yellow-dark-20: rgb(115, 134.7142857143, 49.2857142857);
    --bg-yellow-dark-30: rgb(83.125, 97.375, 35.625);
    /*! Foreground Color */
    /*! black */
    --fg-black: #000;
    /*! black-muted */
    --fg-black-muted: rgba(0, 0, 0, 0.6);
    /*! white */
    --fg-white: #fff;
    /*! white-muted */
    --fg-white-muted: rgba(255, 255, 255, 0.6);
    /*! darkest */
    --fg-darkest: #343a40;
    --fg-darkest-light-10: rgb(74.8620689655, 83.5, 92.1379310345);
    --fg-darkest-light-20: rgb(97.724137931, 109, 120.275862069);
    --fg-darkest-light-30: rgb(122.0344827586, 134.5, 146.9655172414);
    --fg-darkest-dark-10: rgb(29.1379310345, 32.5, 35.8620689655);
    --fg-darkest-dark-20: rgb(6.275862069, 7, 7.724137931);
    --fg-darkest-dark-30: hsl(210, 10.3448275862%, -7.2549019608%);
    /*! dark */
    --fg-dark: #707070;
    --fg-dark-light-10: rgb(137.5, 137.5, 137.5);
    --fg-dark-light-20: #a3a3a3;
    --fg-dark-light-30: rgb(188.5, 188.5, 188.5);
    --fg-dark-dark-10: rgb(86.5, 86.5, 86.5);
    --fg-dark-dark-20: #3d3d3d;
    --fg-dark-dark-30: rgb(35.5, 35.5, 35.5);
    /*! olive */
    --fg-olive: #827a65;
    --fg-olive-light-10: rgb(155.3116883117, 147.4155844156, 126.6883116883);
    --fg-olive-light-20: rgb(177.6103896104, 171.4805194805, 155.3896103896);
    --fg-olive-light-30: rgb(199.9090909091, 195.5454545455, 184.0909090909);
    --fg-olive-dark-10: rgb(101.2987012987, 95.0649350649, 78.7012987013);
    --fg-olive-dark-20: rgb(72.5974025974, 68.1298701299, 56.4025974026);
    --fg-olive-dark-30: rgb(43.8961038961, 41.1948051948, 34.1038961039);
    /*! light */
    --fg-light: #f2f2f2;
    --fg-light-light-10: hsl(0, 0%, 104.9019607843%);
    --fg-light-light-20: hsl(0, 0%, 114.9019607843%);
    --fg-light-light-30: hsl(0, 0%, 124.9019607843%);
    --fg-light-dark-10: rgb(216.5, 216.5, 216.5);
    --fg-light-dark-20: #bfbfbf;
    --fg-light-dark-30: rgb(165.5, 165.5, 165.5);
    /*! yellow */
    --fg-yellow: #abc35b;
    --fg-yellow-light-10: rgb(190.125, 208.6607142857, 128.3392857143);
    --fg-yellow-light-20: rgb(209.25, 222.3214285714, 165.6785714286);
    --fg-yellow-light-30: rgb(228.375, 235.9821428571, 203.0178571429);
    --fg-yellow-dark-10: rgb(146.875, 172.0535714286, 62.9464285714);
    --fg-yellow-dark-20: rgb(115, 134.7142857143, 49.2857142857);
    --fg-yellow-dark-30: rgb(83.125, 97.375, 35.625);
    /** FONT **/
    --main-text-regular: 'main-text-regular';
    --main-text-bold: 'main-text-bold';
    --main-text-link: 'main-text-link';
    --label-text: 'label-text';
    --transition: all 0.4s ease;
}

body {
    margin: 0;
    padding: 0;
    background-color: var(--bg-white);
    font-family: 'main-text-regular', sans-serif;
    font-size: 1.4rem;
    line-height: 2rem;
    overflow-x: hidden !important;
    overflow-clip-margin: 0;
    max-width: 100% !important;
}

/** UTILITY: Colors **/
/*! Background Color */
/*! black */
.bg-black {
    background-color: var(--bg-black);
}

/*! black-muted */
.bg-black-muted {
    background-color: var(--bg-black-muted);
}

/*! white */
.bg-white {
    background-color: var(--bg-white);
}

/*! white-muted */
.bg-white-muted {
    background-color: var(--bg-white-muted);
}

/*! darkest */
.bg-darkest {
    background-color: var(--bg-darkest);
}

.bg-darkest-light-10 {
    background-color: var(--bg-darkest-light-10);
}

.bg-darkest-light-20 {
    background-color: var(--bg-darkest-light-20);
}

.bg-darkest-light-30 {
    background-color: var(--bg-darkest-light-30);
}

.bg-darkest-dark-10 {
    background-color: var(--bg-darkest-dark-10);
}

.bg-darkest-dark-20 {
    background-color: var(--bg-darkest-dark-20);
}

.bg-darkest-dark-30 {
    background-color: var(--bg-darkest-dark-30);
}

/*! dark */
.bg-dark {
    background-color: var(--bg-dark);
}

.bg-dark-light-10 {
    background-color: var(--bg-dark-light-10);
}

.bg-dark-light-20 {
    background-color: var(--bg-dark-light-20);
}

.bg-dark-light-30 {
    background-color: var(--bg-dark-light-30);
}

.bg-dark-dark-10 {
    background-color: var(--bg-dark-dark-10);
}

.bg-dark-dark-20 {
    background-color: var(--bg-dark-dark-20);
}

.bg-dark-dark-30 {
    background-color: var(--bg-dark-dark-30);
}

/*! olive */
.bg-olive {
    background-color: var(--bg-olive);
}

.bg-olive-light-10 {
    background-color: var(--bg-olive-light-10);
}

.bg-olive-light-20 {
    background-color: var(--bg-olive-light-20);
}

.bg-olive-light-30 {
    background-color: var(--bg-olive-light-30);
}

.bg-olive-dark-10 {
    background-color: var(--bg-olive-dark-10);
}

.bg-olive-dark-20 {
    background-color: var(--bg-olive-dark-20);
}

.bg-olive-dark-30 {
    background-color: var(--bg-olive-dark-30);
}

/*! light */
.bg-light {
    background-color: var(--bg-light);
}

.bg-light-light-10 {
    background-color: var(--bg-light-light-10);
}

.bg-light-light-20 {
    background-color: var(--bg-light-light-20);
}

.bg-light-light-30 {
    background-color: var(--bg-light-light-30);
}

.bg-light-dark-10 {
    background-color: var(--bg-light-dark-10);
}

.bg-light-dark-20 {
    background-color: var(--bg-light-dark-20);
}

.bg-light-dark-30 {
    background-color: var(--bg-light-dark-30);
}

/*! yellow */
.bg-yellow {
    background-color: var(--bg-yellow);
}

.bg-yellow-light-10 {
    background-color: var(--bg-yellow-light-10);
}

.bg-yellow-light-20 {
    background-color: var(--bg-yellow-light-20);
}

.bg-yellow-light-30 {
    background-color: var(--bg-yellow-light-30);
}

.bg-yellow-dark-10 {
    background-color: var(--bg-yellow-dark-10);
}

.bg-yellow-dark-20 {
    background-color: var(--bg-yellow-dark-20);
}

.bg-yellow-dark-30 {
    background-color: var(--bg-yellow-dark-30);
}

/*! Foreground Color */
/*! black */
.fg-black {
    color: var(--fg-black);
}

/*! black-muted */
.fg-black-muted {
    color: var(--fg-black-muted);
}

/*! white */
.fg-white {
    color: var(--fg-white);
}

/*! white-muted */
.fg-white-muted {
    color: var(--fg-white-muted);
}

/*! darkest */
.fg-darkest {
    color: var(--fg-darkest);
}

.fg-darkest-light-10 {
    color: var(--fg-darkest-light-10);
}

.fg-darkest-light-20 {
    color: var(--fg-darkest-light-20);
}

.fg-darkest-light-30 {
    color: var(--fg-darkest-light-30);
}

.fg-darkest-dark-10 {
    color: var(--fg-darkest-dark-10);
}

.fg-darkest-dark-20 {
    color: var(--fg-darkest-dark-20);
}

.fg-darkest-dark-30 {
    color: var(--fg-darkest-dark-30);
}

/*! dark */
.fg-dark {
    color: var(--fg-dark);
}

.fg-dark-light-10 {
    color: var(--fg-dark-light-10);
}

.fg-dark-light-20 {
    color: var(--fg-dark-light-20);
}

.fg-dark-light-30 {
    color: var(--fg-dark-light-30);
}

.fg-dark-dark-10 {
    color: var(--fg-dark-dark-10);
}

.fg-dark-dark-20 {
    color: var(--fg-dark-dark-20);
}

.fg-dark-dark-30 {
    color: var(--fg-dark-dark-30);
}

/*! olive */
.fg-olive {
    color: var(--fg-olive);
}

.fg-olive-light-10 {
    color: var(--fg-olive-light-10);
}

.fg-olive-light-20 {
    color: var(--fg-olive-light-20);
}

.fg-olive-light-30 {
    color: var(--fg-olive-light-30);
}

.fg-olive-dark-10 {
    color: var(--fg-olive-dark-10);
}

.fg-olive-dark-20 {
    color: var(--fg-olive-dark-20);
}

.fg-olive-dark-30 {
    color: var(--fg-olive-dark-30);
}

/*! light */
.fg-light {
    color: var(--fg-light);
}

.fg-light-light-10 {
    color: var(--fg-light-light-10);
}

.fg-light-light-20 {
    color: var(--fg-light-light-20);
}

.fg-light-light-30 {
    color: var(--fg-light-light-30);
}

.fg-light-dark-10 {
    color: var(--fg-light-dark-10);
}

.fg-light-dark-20 {
    color: var(--fg-light-dark-20);
}

.fg-light-dark-30 {
    color: var(--fg-light-dark-30);
}

/*! yellow */
.fg-yellow {
    color: var(--fg-yellow);
}

.fg-yellow-light-10 {
    color: var(--fg-yellow-light-10);
}

.fg-yellow-light-20 {
    color: var(--fg-yellow-light-20);
}

.fg-yellow-light-30 {
    color: var(--fg-yellow-light-30);
}

.fg-yellow-dark-10 {
    color: var(--fg-yellow-dark-10);
}

.fg-yellow-dark-20 {
    color: var(--fg-yellow-dark-20);
}

.fg-yellow-dark-30 {
    color: var(--fg-yellow-dark-30);
}

.main-text-regular-12 {
    font-family: 'main-text-regular';
    font-size: 0.75rem;
}

.main-text-regular-14 {
    font-family: 'main-text-regular';
    font-size: 0.875rem;
}

.main-text-regular-16 {
    font-family: 'main-text-regular';
    font-size: 1rem;
}

.main-text-regular-18 {
    font-family: 'main-text-regular';
    font-size: 1.125rem;
}

.main-text-regular-20 {
    font-family: 'main-text-regular';
    font-size: 1.25rem;
}

.main-text-regular-22 {
    font-family: 'main-text-regular';
    font-size: 1.375rem;
}

.main-text-bold-12 {
    font-family: 'main-text-bold';
    font-size: 0.75rem;
}

.main-text-bold-14 {
    font-family: 'main-text-bold';
    font-size: 0.875rem;
}

.main-text-bold-16 {
    font-family: 'main-text-bold';
    font-size: 1rem;
}

.main-text-bold-18 {
    font-family: 'main-text-bold';
    font-size: 1.125rem;
}

.main-text-bold-20 {
    font-family: 'main-text-bold';
    font-size: 1.25rem;
}

.main-text-bold-22 {
    font-family: 'main-text-bold';
    font-size: 1.375rem;
}

.main-text-link-12 {
    font-family: 'main-text-link';
    font-size: 0.75rem;
}

.main-text-link-14 {
    font-family: 'main-text-link';
    font-size: 0.875rem;
}

.main-text-link-16 {
    font-family: 'main-text-link';
    font-size: 1rem;
}

.main-text-link-18 {
    font-family: 'main-text-link';
    font-size: 1.125rem;
}

.main-text-link-20 {
    font-family: 'main-text-link';
    font-size: 1.25rem;
}

.main-text-link-22 {
    font-family: 'main-text-link';
    font-size: 1.375rem;
}

.label-text-12 {
    font-family: 'label-text';
    font-size: 0.75rem;
}

.label-text-14 {
    font-family: 'label-text';
    font-size: 0.875rem;
}

.label-text-16 {
    font-family: 'label-text';
    font-size: 1rem;
}

.label-text-18 {
    font-family: 'label-text';
    font-size: 1.125rem;
}

.label-text-20 {
    font-family: 'label-text';
    font-size: 1.25rem;
}

.label-text-22 {
    font-family: 'label-text';
    font-size: 1.375rem;
}

.label-text-24 {
    font-family: 'label-text';
    font-size: 1.5rem;
}

.label-text-26 {
    font-family: 'label-text';
    font-size: 1.625rem;
}

.label-text-28 {
    font-family: 'label-text';
    font-size: 1.75rem;
}

.text-align-left {
    text-align: left;
}

.text-align-center {
    text-align: center;
}

.text-align-right {
    text-align: right;
}

.is-flex {
    display: flex;
}

.is-row {
    flex-direction: row;
}

.is-column {
    flex-direction: column;
}

.float-right {
    justify-content: flex-end;
}

.float-start {
    justify-content: flex-start;
}

.float-center {
    justify-content: center;
}

.align-start {
    align-items: flex-start;
}

.align-center {
    align-items: center;
}

.align-end {
    align-items: flex-end;
}

a {
    color: rgba(0, 0, 0, 0.4);
    transition: all 0.4s ease;
    font-family: 'main-text-bold';
}
a:hover {
    color: rgb(0, 0, 0);
}

p a {
    color: rgba(137, 126, 108, 0.6);
    transition: all 0.4s ease;
    font-family: 'main-text-bold';
}

h1 {
    font-family: 'heading';
    font-size: 2.625rem;
}

h2 {
    font-family: 'heading';
    font-size: 2.125rem;
}

h3 {
    font-family: 'heading';
    font-size: 1.5rem;
}

h4 {
    font-family: 'heading';
    font-size: 1.625rem;
}

h5 {
    font-family: 'heading';
    font-size: 1.5rem;
}

h6 {
    font-family: 'heading';
    font-size: 1.375rem;
}

.Logo img {
    height: 140px;
    cursor: pointer;
}
.Button {
    background-color: var(--bg-olive);
    display: inline-block;
    padding: 8px 16px;
    text-transform: lowercase;
    color: var(--fg-white);
    font-weight: 400;
    font-size: 16px;
    cursor: pointer;
    transition: var(--transition);
    max-width: 240px;
    text-align: center;
    min-width: 160px;
}
.Button:hover {
    background-color: var(--bg-olive-dark-10);
    box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.45);
}
.Button[data-active='false'] {
    background-color: var(--bg-white);
    color: var(--fg-black);
}
.Button[data-active='false']:hover {
    background-color: var(--bg-olive-dark-10);
    box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.45);
    color: var(--fg-white);
}
.Button[data-object-type='pill'] {
    border-radius: 50px;
    min-width: 60px;
    text-wrap: nowrap;
}
.Button[data-object-type='inverse'] {
    min-width: 120px;
    background-color: transparent;
    border: 2px solid var(--bg-white);
    color: var(--fg-white);
}
.Button[data-object-type='inverse']:hover {
    background-color: var(--bg-white);
    box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.45);
    color: var(--fg-olive);
}
@media (max-width: 480px) {
    .Button {
        padding: 8px 12px;
        font-size: 10px;
    }
}
@media (max-width: 768px) {
    .Button {
        padding: 8px 12px;
        font-size: 10px;
    }
}
.Paragraph {
    margin-bottom: 20px;
}
.Paragraph li {
    margin: 0;
}
.Paragraph table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
}
.Paragraph table th {
    text-align: left;
    font-weight: bold;
    color: var(--fg-black);
}
.Paragraph table td {
    color: var(--fg-black-muted);
}
.OptionItem {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 20px;
    cursor: pointer;
}
.OptionItem:hover img {
    box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.45);
}
.OptionItem a {
    color: var(--fg-olive) !important;
}
@media (max-width: 768px) {
    .OptionItem {
        margin-top: 20px;
    }
    .OptionItem h4 {
        font-size: 16px;
    }
}
.InputField {
    width: 100%;
    padding: 10px 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid transparent;
    border-bottom: 1px solid var(--bg-olive);
}
.InputField:focus {
    outline: none;
    background-color: var(--bg-olive-light-20);
    color: var(--fg-white);
}
.TextArea {
    width: 100%;
    padding: 10px 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid transparent;
    border-bottom: 1px solid var(--bg-olive);
}
.ThankYou {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px;
    box-shadow: 0px 0px 10px 0px var(--bg-olive-light-30);
    background-color: var(--bg-olive-light-30);
}
.Spinner {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 400px;
}
.MakeAppointment {
    position: fixed;
    bottom: 60px;
    right: 20px;
    background-color: var(--bg-yellow);
    color: #fff;
    font-size: 16px;
    width: 180px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.45);
    cursor: pointer;
}
.MakeAppointment:hover {
    background-color: var(--bg-yellow-dark-20);
}
@media (max-width: 480px) {
    .MakeAppointment {
        bottom: 80px;
    }
}
.InfoBlock {
    width: 100%;
    overflow-x: hidden !important;
    min-height: 400px;
}
.InfoBlock h2 {
    margin-bottom: 20px;
}
.InfoBlock .description {
    width: 40vw;
}
.InfoBlock .shadowed {
    box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.45);
}
.InfoBlock .InfoBlock__left .left-row {
    background-color: var(--bg-white);
    min-height: 400px;
    max-height: 800px;
}
.InfoBlock .InfoBlock__left .left-image {
    background-color: var(--bg-darkest);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
}
.InfoBlock .InfoBlock__left .left-image img {
    margin-left: -320px;
    transform: scaleX(-1);
}
.InfoBlock .InfoBlock__left .left-content {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10%;
    max-height: 800px;
}
.InfoBlock .InfoBlock__right .right-row {
    background-color: var(--bg-light);
    min-height: 400px;
    max-height: 800px;
}
.InfoBlock .InfoBlock__right .right-image {
    background-color: var(--bg-olive);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
}
.InfoBlock .InfoBlock__right .right-image img {
    margin-right: -320px;
}
.InfoBlock .InfoBlock__right .right-image img .shadowed {
    box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.45);
}
.InfoBlock .InfoBlock__right .right-content {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10%;
    max-height: 500px;
}
@media (max-width: 1024px) {
    .InfoBlock .description {
        width: 100%;
    }
    .InfoBlock .InfoBlock__right .right-row {
        background-color: var(--bg-light);
        min-height: 0;
        max-height: none;
    }
    .InfoBlock .InfoBlock__right .right-image {
        background-color: var(--bg-olive);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 30px;
    }
    .InfoBlock .InfoBlock__right .right-image img {
        margin-right: 0;
        width: 80%;
    }
    .InfoBlock .InfoBlock__right .right-image img .shadowed {
        box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.45);
    }
    .InfoBlock .InfoBlock__right .right-content {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10%;
        max-height: none;
    }
    .InfoBlock .InfoBlock__left .left-row {
        background-color: var(--bg-white);
        min-height: 0;
        max-height: none;
    }
    .InfoBlock .InfoBlock__left .left-image {
        background-color: var(--bg-darkest);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 30px;
    }
    .InfoBlock .InfoBlock__left .left-image img {
        width: 80%;
        margin-left: 0;
        transform: scaleX(-1);
    }
    .InfoBlock .InfoBlock__left .left-content {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10%;
        max-height: none;
    }
}
.ProductBlock {
    padding: 0 30px;
}
.ProductBlock .content {
    padding: 10px;
    height: 120px;
    background-color: var(--bg-white);
    display: flex;
    align-items: center;
}
.ProductBlock:hover .selector {
    background-color: var(--bg-olive-dark-20);
}
.ProductBlock .title {
    font-size: 16px;
    line-height: 20px;
    color: var(--fg-black);
}
.ProductBlock .price {
    font-size: 16px;
    line-height: 20px;
    color: var(--fg-black-muted);
}
.ProductBlock .selector {
    position: relative;
    top: -85px;
    left: 95%;
    background-color: var(--bg-olive);
    width: 50px;
    height: 50px;
    z-index: 1000;
    color: #fff !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 28px;
    transition: var(--transition);
}
.ProductBlock img {
    width: 100%;
}
.ProductBlock .text {
    color: var(--fg-black-muted);
    font-size: 12px;
    line-height: 16px;
    width: 80%;
}
@media (max-width: 768px) {
    .ProductBlock {
        display: none;
    }
}
.Options {
    padding: 80px 20px;
    overflow: hidden !important;
}
@media (max-width: 768px) {
    .Options {
        padding: 20px 20px;
    }
}
.PriceTag {
    font-weight: normal !important;
    font-family: 'main-text-regular', sans-serif;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    padding: 10px !important;
    box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.2);
    justify-content: center;
    align-items: center;
    background-color: var(--bg-yellow);
}
.PriceTag:hover {
    background-color: var(--bg-yellow-dark-20);
    transform: rotate(0deg);
}
.PriceTag .text-big {
    font-size: 1rem !important;
    font-weight: 700;
    color: var(--fg-black);
    text-align: center;
    width: 100%;
}
.PriceTag .text-medium {
    color: var(--fg-white);
    font-size: 1rem !important;
    text-align: center;
    width: 100%;
    padding: 5px;
}
.PriceTag .text-small {
    font-size: 1rem;
}
@media (max-width: 768px) {
    .PriceTag .text-big .text-medium {
        font-size: 0.8rem !important;
    }
}
.MobileBar {
    display: none;
}
@media (max-width: 768px) {
    .MobileBar {
        display: flex;
        background-color: var(--bg-light);
    }
    .MobileBar .mcol-1 {
        background-color: var(--bg-darkest);
    }
    .MobileBar .mcol-1 a {
        color: #fff !important;
    }
    .MobileBar .mcol-2 {
        background-color: var(--bg-olive);
    }
    .MobileBar .mcol-2 a {
        color: #fff !important;
    }
    .MobileBar .MobileBar__col {
        padding: 8px;
        flex-direction: column;
        font-size: 12px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .MobileBar .MobileBar__col img {
        width: 80px;
    }
}
.Hero {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 80vh;
    margin: 0 !important;
    padding: 0 !important;
    overflow-x: hidden !important;
}
.Hero .overlay {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 80vh;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 140px;
}
.Hero .hero-content .hero-text {
    width: 60vw;
    color: var(--fg-white) !important;
    padding-left: 40px;
}
.Hero .hero-content .hero-description {
    margin-top: 40px;
}
.Hero .hero-blocks {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 40px;
}
.Hero .hero-blocks .block {
    width: 300px;
    height: 100px;
    background-color: var(--bg-white);
}
.Hero[data-object-type='other'] {
    min-height: 65vh;
}
.Hero[data-object-type='other'] .overlay {
    min-height: 65vh;
}
@media (max-width: 768px) {
    .Hero {
        height: 65vh;
    }
    .Hero .Logo img {
        width: 200px;
        margin-left: -20px;
    }
    .Hero .overlay {
        height: 65vh;
        padding: 20px;
        gap: 0;
    }
    .Hero .hero-content {
        width: 100%;
    }
    .Hero .hero-content .hero-text {
        width: 100vw;
        color: var(--fg-white) !important;
        padding-left: 0;
    }
    .Hero .hero-content .hero-slogan h2 {
        font-size: 1.4rem;
        margin-bottom: 20px;
        width: 80%;
    }
    .Hero .hero-content .hero-description {
        margin-top: 40px;
    }
    .Hero .hero-content .hero-description h2 {
        font-size: 1rem;
        margin-bottom: 20px;
    }
    .Hero .hero-content .hero-description p {
        font-size: 1rem;
        line-height: 1.2;
        width: 80%;
    }
}
.Footer {
    background-color: var(--bg-darkest);
    color: var(--fg-white);
    overflow-x: hidden !important;
    padding: 4% 0 100px;
}
.Footer .text {
    color: var(--fg-white-muted);
}
.Footer a {
    color: var(--fg-white) !important;
}
.Footer .data-col {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    padding: 20px 0;
}
.Footer .data-col-text {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    padding: 20px 0;
}
.Footer .social {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 20px 0;
}
.Footer .social a img {
    width: 30px;
}
.Footer .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 2%;
    height: 80px;
}
.Footer .logo img {
    width: 100px;
}
.Footer .full-width-center {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 0;
}
.Footer .links ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    justify-content: center;
    gap: 40px;
}
.Footer .links ul li {
    height: 30px;
}
.Footer .links ul li a {
    opacity: 0.6;
    margin-bottom: 20px;
    font-weight: normal;
    font-family: 'main-text-regular', sans-serif !important;
}
.Footer .links ul li a:hover {
    opacity: 1;
}
@media (max-width: 768px) {
    .Footer .data-col,
    .Footer .data-col-text {
        margin-top: 20px;
        flex-direction: column;
        align-items: center;
    }
    .Footer .data-col ul li,
    .Footer .data-col-text ul li {
        text-align: center;
    }
}
.PriceBar {
    background-color: var(--bg-white);
    padding: 4% 0 4% 0;
    overflow-x: hidden !important;
}
.PriceBar h2 {
    margin: 40px 0;
}
@media (max-width: 768px) {
    .PriceBar {
        padding: 5%;
    }
    .PriceBar h2 {
        margin-top: 20px;
    }
}
.AboutUs {
    background-color: var(--bg-olive);
    padding: 4% 0;
    overflow-x: hidden !important;
}
.AboutUs .button-bar {
    display: flex;
    justify-content: center;
    margin-top: 40px;
}
.AboutUs img {
    box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.45);
}
.AboutUs h2 {
    margin: 0 0 40px 0;
}
.AboutUs a {
    color: var(--fg-white);
    text-decoration: none;
}
@media (max-width: 768px) {
    .AboutUs {
        padding: 5%;
    }
    .AboutUs h2 {
        margin-top: 20px;
    }
}
.options {
    margin-top: -60px;
}

.ProductList .ProductList__item {
    padding: 5%;
}
.ProductList .ProductList__item p {
    font-size: 12px;
    text-align: center;
    margin-top: 20px;
    color: var(--fg-black);
}
.ProductList .ProductList__item[data-object-type='materials'] img {
    box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.45);
}
.ProductList .ProductList__item:nth-child(1n) {
    background-color: var(--bg-light);
}
.ProductList .ProductList__item:nth-child(2n) {
    background-color: var(--bg-olive);
}
.ProductList .ProductList__item:nth-child(2n) p {
    color: var(--fg-white);
}
.ProductList .ProductList__item:nth-child(7n) {
    background-color: var(--bg-white);
}
.ProductList .ProductList__item:nth-child(4n + 2) img {
    transform: scaleX(-1);
}
.ProductList .ProductList__item:nth-child(4n + 5) {
    background-color: var(--bg-darkest);
}
.ProductList .ProductList__item:nth-child(4n + 5) p {
    color: var(--fg-white);
}
@media (max-width: 768px) {
    .ProductList .ProductList__item:nth-child(4n + 1),
    .ProductList .ProductList__item:nth-child(4n + 4) {
        background-color: var(--bg-olive);
    }
    .ProductList .ProductList__item:nth-child(4n + 1) img,
    .ProductList .ProductList__item:nth-child(2n + 1) img {
        transform: scaleX(1);
    }
    .ProductList .ProductList__item:nth-child(6n + 2) {
        background-color: var(--bg-darkest);
    }
    .ProductList .ProductList__item:nth-child(3n) img {
        transform: scaleX(-1);
    }
    .ProductList .ProductList__item:nth-child(4n + 2) {
        background-color: var(--bg-light);
    }
}
.Menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: space-between;
    padding: 2% 9%;
    transition: var(--transition);
    z-index: 1000;
}
.Menu .menu-desktop {
    display: block;
}
.Menu .menu-mobile,
.Menu .overlay-menu {
    display: none;
}
.Menu .menu-logo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.Menu .menu-logo img {
    display: none;
    height: 60px;
}
.Menu[data-theme='normal'] {
    background-color: transparent;
    box-shadow: none;
}
.Menu[data-theme='normal'] .menu-mobile,
.Menu[data-theme='normal'] .overlay-menu {
    color: white;
}
.Menu[data-theme='normal'] .menu-mobile ul li,
.Menu[data-theme='normal'] .overlay-menu ul li {
    color: var(--fg-olive) !important;
}
.Menu[data-theme='normal'] .menu-mobile a,
.Menu[data-theme='normal'] .overlay-menu a {
    color: var(--fg-olive) !important;
}
.Menu[data-theme='normal'] ul {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
}
.Menu[data-theme='normal'] ul li {
    margin: 0 20px;
    color: white;
    font-family: 'main-text-bold', serif;
    cursor: pointer;
}
.Menu[data-theme='normal'] ul li a {
    color: white;
    text-decoration: none;
}
.Menu[data-theme='normal'] ul li[data-active='true'] {
    border-bottom: 4px solid var(--fg-white);
}
.Menu[data-theme='normal'] ul li[data-object-type='product-link'] a {
    opacity: 0.5;
}
.Menu[data-theme='scroll'] {
    background-color: var(--bg-white);
    box-shadow: 0 0 22px 8px rgba(0, 0, 0, 0.25);
}
.Menu[data-theme='scroll'] .menu-logo img {
    display: block;
}
.Menu[data-theme='scroll'] ul {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
}
.Menu[data-theme='scroll'] ul li {
    margin: 0 10px;
    font-family: 'main-text-bold';
    cursor: pointer;
    color: var(--fg-dark);
}
.Menu[data-theme='scroll'] ul li a {
    color: var(--fg-dark);
    text-decoration: none;
}
.Menu[data-theme='scroll'] ul li[data-active='true'] {
    border-bottom: 4px solid var(--fg-olive);
}
.Menu[data-theme='scroll'] ul li[data-object-type='product-link'] a {
    color: var(--fg-light-dark-20);
    opacity: 1;
}
@media (max-width: 1200px) {
    .Menu .menu-desktop {
        display: none;
    }
    .Menu .menu-mobile {
        display: block;
        position: fixed;
        right: 20px;
        top: 20px;
        color: var(--fg-olive);
        z-index: 10000;
    }
    .Menu .overlay-menu {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: var(--bg-white);
        z-index: 9999;
        padding: 30px;
    }
    .Menu .overlay-menu .mobile-logo img {
        height: 40px;
    }
    .Menu .overlay-menu .menu-container {
        padding: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .Menu .overlay-menu .menu-container ul {
        flex-direction: column;
        gap: 20px;
    }
    .Menu .overlay-menu .menu-container ul li {
        width: 200px;
    }
}
.ContactForm .float-right {
    display: flex;
    align-items: flex-end;
}
.Navigation {
    margin: 0;
    padding: 0;
}
.InformationPage h1 {
    margin-top: 40px;
    margin-bottom: 20px;
}
.InformationPage .article {
    margin-top: 40px;
}
.InformationPage .article h3 {
    margin-bottom: 20px;
}
.InformationPage .contact-info {
    margin-top: 40px;
    margin-bottom: 80px;
}
@media (max-width: 768px) {
    .InformationPage .page-content {
        padding: 20px;
    }
}
.ProductPage {
    background-color: var(--bg-light);
}
.ProductPage .ProductPage__filters {
    margin-left: 5%;
    padding: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}
@media (max-width: 1024px) {
    .ProductPage .ProductPage__filters {
        margin-left: 1%;
        padding: 20px 10px;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        align-items: center;
        gap: 10px;
    }
}

