.Hero {
    $size: 65vh;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: $size + 15;
    margin: 0 !important;
    padding: 0 !important;
    overflow-x: hidden !important;

    .overlay {
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: $size + 15;
        background-color: rgba(0, 0, 0, 0.6);
        display: flex;
        //flex-grow: 1;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        //padding: 8% 10% 4%;
        //padding: 15% 10% 6%;
        gap: 140px;
    }

    .hero-content {
        //margin-left: 60px;
        .hero-text {
            width: 60vw;
            color: var(--fg-white) !important;
            padding-left: 40px;
        }

        .hero-description {
            margin-top: 40px;
        }
    }

    .hero-blocks {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        gap: 40px;
        .block {
            width: 300px;
            height: 100px;
            background-color: var(--bg-white);
        }
    }

    &[data-object-type='other'] {
        min-height: $size;
        .overlay {
            min-height: $size;
            // padding: 15% 10% 6%;
        }
    }

    // XS - Phone
    @media (max-width: 480px) {
    }

    // S - Tablet
    @media (max-width: 768px) {
        .Logo {
            img {
                width: 200px;
                margin-left: -20px;
            }
        }
        height: $size;
        .overlay {
            height: $size;
            padding: 20px;
            gap: 0;
        }

        .hero-content {
            width: 100%;
            .hero-text {
                width: 100vw;
                color: var(--fg-white) !important;
                padding-left: 0;
            }

            .hero-slogan {
                h2 {
                    font-size: 1.4rem;
                    margin-bottom: 20px;
                    width: 80%;
                }
            }

            .hero-description {
                margin-top: 40px;

                h2 {
                    font-size: 1rem;
                    margin-bottom: 20px;
                }

                p {
                    font-size: 1rem;
                    line-height: 1.2;
                    width: 80%;
                }
            }
        }
    }

    // M - Tablet/Laptop
    @media (max-width: 1024px) {
    }

    // L - High Res
    @media (max-width: 1200px) {
    }

    // XL - Ultra High Res
    @media (min-width: 1201px) {
    }
}
