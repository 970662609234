.ProductBlock {
    padding: 0 30px;

    .content {
        padding: 10px;
        //margin-right: 30px;
        //width: 320px;
        height: 120px;
        background-color: var(--bg-white);
        display: flex;
        align-items: center;
    }

    &:hover {
        .selector {
            background-color: var(--bg-olive-dark-20);
        }
    }

    .title {
        font-size: 16px;
        line-height: 20px;
        color: var(--fg-black);
    }

    .price {
        font-size: 16px;
        line-height: 20px;
        color: var(--fg-black-muted);
    }

    .selector {
        position: relative;
        top: -85px;
        left: 95%;
        background-color: var(--bg-olive);
        width: 50px;
        height: 50px;
        z-index: 1000;
        //margin-left: 290px;
        //margin-top: -100px;
        color: #fff !important;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 28px;
        transition: var(--transition);
    }

    img {
        width: 100%;
    }

    .text {
        color: var(--fg-black-muted);
        font-size: 12px;
        line-height: 16px;
        width: 80%;
    }

    // XS - Phone
    @media (max-width: 480px) {
    }

    // S - Tablet
    @media (max-width: 768px) {
        display: none;
    }

    // M - Tablet/Laptop
    @media (max-width: 1024px) {
    }

    // L - High Res
    @media (max-width: 1200px) {
    }

    // XL - Ultra High Res
    @media (min-width: 1201px) {
    }
}
