@use 'sass:map';
@use 'sass:string';
@use '../functions/functions';
@use '../tokens/config';

@each $style in config.$fonts {
    $type: map.get($style, 'type');
    @each $s in map.get($style, 'sizes') {
        $v: $type + '-' + $s;
        .#{$v} {
            font-family: string.quote($type);
            font-size: functions.pxToRem($s);
        }
    }
}

.text-align-left {
    text-align: left;
}

.text-align-center {
    text-align: center;
}

.text-align-right {
    text-align: right;
}
