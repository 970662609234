@use 'sass:map';
@use '../tokens/config';

body {
    margin: map.get(config.$page, 'margin');
    padding: map.get(config.$page, 'padding');
    background-color: var(--bg- + map.get(config.$page, 'backgroundColor'));
    font-family: 'main-text-regular', sans-serif;
    font-size: 1.4rem;
    line-height: 2rem;
    //overflow-x: hidden !important;
    overflow-x: hidden !important;
    overflow-clip-margin: 0;
    max-width: 100% !important;
}
