.ThankYou {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px;
    box-shadow: 0px 0px 10px 0px var(--bg-olive-light-30);
    background-color: var(--bg-olive-light-30);

    // XS - Phone
    @media (max-width: 480px) {
    }

    // S - Tablet
    @media (max-width: 768px) {
    }

    // M - Tablet/Laptop
    @media (max-width: 1024px) {
    }

    // L - High Res
    @media (max-width: 1200px) {
    }

    // XL - Ultra High Res
    @media (min-width: 1201px) {
    }
}
