.ProductList {
    .ProductList__item {
        padding: 5%;

        p {
            font-size: 12px;
            text-align: center;
            margin-top: 20px;
            color: var(--fg-black);
        }

        &[data-object-type='materials'] {
            img {
                box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.45);
            }
        }

        &:nth-child(1n) {
            background-color: var(--bg-light);
        }

        &:nth-child(2n) {
            background-color: var(--bg-olive);
            p {
                color: var(--fg-white);
            }
        }

        &:nth-child(7n) {
            background-color: var(--bg-white);
        }

        &:nth-child(4n + 2) {
            //&:nth-child(2n + 1) {
            img {
                transform: scaleX(-1);
            }
        }

        &:nth-child(4n + 5) {
            background-color: var(--bg-darkest);
            p {
                color: var(--fg-white);
            }
        }
    }

    // XS - Phone
    @media (max-width: 480px) {
    }

    // S - Tablet
    @media (max-width: 768px) {
        .ProductList__item {
            &:nth-child(4n + 1),
            &:nth-child(4n + 4) {
                background-color: var(--bg-olive);
            }

            &:nth-child(4n + 1),
            &:nth-child(2n + 1) {
                img {
                    transform: scaleX(1);
                }
            }

            &:nth-child(6n + 2) {
                background-color: var(--bg-darkest);
            }

            &:nth-child(3n) {
                img {
                    transform: scaleX(-1);
                }
            }

            &:nth-child(4n + 2) {
                background-color: var(--bg-light);
            }
        }
    }

    // M - Tablet/Laptop
    @media (max-width: 1024px) {
    }

    // L - High Res
    @media (max-width: 1200px) {
    }

    // XL - Ultra High Res
    @media (min-width: 1201px) {
    }
}
