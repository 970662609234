.Paragraph {
    margin-bottom: 20px;

    li {
        margin: 0;
    }

    table {
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;

        th {
            text-align: left;
            font-weight: bold;
            color: var(--fg-black);
        }
        td {
            color: var(--fg-black-muted);
        }
    }

    // XS - Phone
    @media (max-width: 480px) {
    }

    // S - Tablet
    @media (max-width: 768px) {
    }

    // M - Tablet/Laptop
    @media (max-width: 1024px) {
    }

    // L - High Res
    @media (max-width: 1200px) {
    }

    // XL - Ultra High Res
    @media (min-width: 1201px) {
    }
}
